import React from "react";
import { graphql } from "gatsby";

import Layout from "../../components/layout";

import heroImage from "../../images/privacy-cookies/cover.jpg";
import { respH } from "../../lib/helpers";
import Seo from "../../components/seo";

import "./index.styles.scss";

const PrivacyCookies = ({ data }) => {
  const lastUpdatePrivacy = data.site?.siteMetadata?.lastUpdatePrivacy;
  const domain = data.site?.siteMetadata?.domain || "default domain";
  const mail = data.site?.siteMetadata?.mail || "default mail";
  const siteUrl = data.site?.siteMetadata?.siteUrl || "default url";

  return (
    <Layout pageClass="contatti">
      <Seo title={`Privacy e cookies policy`} />
      <section
        className="hero is-large"
        style={{
          backgroundImage: `linear-gradient( 109.6deg, rgba(156,252,248,0.3), transparent, rgba(110,123,251,0.3)), url(${heroImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center center",
          height: "100%",
          overflow: "hidden",
        }}
      >
        {/* <div className='hero-body'> */}
        {/*   <h1 className={`title ${respH(1)} has-text-white has-text-centered is-capitalized`}>privacy e cookies policy </h1> */}
        {/* </div> */}
      </section>

      <section className="section is-medium pb-4">
        <div className="container has-max-ch mx-auto content">
          <h1 className={`title ${respH(1)}`}>Privacy e cookies policy</h1>
          <div className="block">
            <p>
              Questa è la <span className="is-italic">cookie policy</span> per{" "}
              {`${domain}`} accessibile all'indirizzo{" "}
              <a
                href={`${siteUrl}`}
                target="_blank"
                rel="noreferrer"
              >{`${siteUrl}`}</a>
            </p>
            <p>
              L'autore di questa <span className="is-italic">policy</span> e la
              persona responsabile per questo sito relativamente alle leggi di
              protezione dei dati è:
            </p>
          </div>
          <ul className="block">
            <li>La Fucina dei Talenti Srls</li>
            <li>Italy</li>
            <li>email: {`${mail}`}</li>
          </ul>
        </div>
      </section>

      <section className="section pb-4">
        <div className="container has-max-ch mx-auto">
          <h2 className={`title ${respH(3)}`}>1. Introduzione</h2>
          <p>
            Rispettiamo la tua privacy e ci impegniamo a proteggere i tuoi dati
            personali. Questa Informativa sulla Privacy spiega come raccogliamo,
            utilizziamo e proteggiamo le tue informazioni quando visiti il
            nostro sito web, ospitato su <strong>Cloudflare Pages</strong>.
          </p>
          <p>
            Ci conformiamo al{" "}
            <strong>
              Regolamento Generale sulla Protezione dei Dati (GDPR)
            </strong>
            .
          </p>
        </div>
      </section>

      <section className="section pb-4">
        <div className="container has-max-ch mx-auto">
          <h2 className={`title ${respH(3)}`}>
            2. Dati che Raccogliamo e Perché
          </h2>
          <p>
            Non raccogliamo dati personali a meno che non vengano forniti
            volontariamente. Tuttavia, il nostro provider di hosting,{" "}
            <strong>Cloudflare</strong>, potrebbe elaborare i seguenti dati:
          </p>
          <ul>
            <li>
              <strong>Indirizzo IP</strong> (per sicurezza e ottimizzazione
              delle prestazioni).
            </li>
            <li>
              <strong>Informazioni su dispositivo e browser</strong> (per
              garantire il corretto funzionamento del sito).
            </li>
            <li>
              <strong>Log del server</strong> (per rilevare e prevenire abusi).
            </li>
            <li>
              <strong>Dati analitici</strong> (se attivati, per monitorare le
              prestazioni del sito).
            </li>
          </ul>
        </div>
      </section>

      <section className="section pb-4">
        <div className="container has-max-ch mx-auto">
          <h2 className={`title ${respH(3)}`}>
            3. Base Giuridica per il Trattamento (GDPR)
          </h2>
          <ul>
            <li>
              <strong>Interessi Legittimi</strong> – Per garantire la sicurezza
              e le prestazioni del sito.
            </li>
            <li>
              <strong>Consenso</strong> – Quando fornisci esplicitamente i tuoi
              dati (es. iscrizione alla newsletter).
            </li>
            <li>
              <strong>Necessità Contrattuale</strong> – Se interagisci con noi
              in una transazione commerciale.
            </li>
          </ul>
        </div>
      </section>

      <section className="section pb-4">
        <div className="container has-max-ch mx-auto content">
          <h2 className={`title ${respH(3)}`}>4. Cookie e Tracciamento</h2>
          <p>
            Il nostro sito potrebbe utilizzare{" "}
            <strong>cookie essenziali</strong> per sicurezza e prestazioni. Se
            vengono utilizzati strumenti di tracciamento aggiuntivi, verrà
            richiesto il tuo <strong>consenso esplicito</strong>
            tramite un banner cookie.
          </p>
          <p>
            I cookie sono piccoli file che vengono scaricati sul tuo computer,
            per migliorare la tua visita o per altri scopi. Per maggiori
            informazioni sui cookies, vedi{" "}
            <a
              href="https://it.wikipedia.org/wiki/Cookie"
              target="_blank"
              rel="noreferrer noopener"
            >
              <u>Wikipedia su HTTP Cookies</u>
            </a>
            .
          </p>
          <p>
            In ogni momento puoi revocare il tuo consenso all'utilizzo dei
            cookies cliccando su{" "}
            <span className="is-italic">Cancella preferenze cookies</span> nel
            <span className="is-italic">footer del sito.</span> Questa azione
            cancella i cookies precedentemente salvati da questo sito.
          </p>
          <p>
            Puoi cancellare i cookies anche seguendo le istruzioni specifiche
            per il tuo <span className="is-italic">browser</span>
          </p>
          <ul className="list">
            <li className="list-item">
              <a
                href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&amp;hl=it"
                target="_blank"
                rel="noreferrer noopener"
              >
                Deleting cookies in Chrome
              </a>
            </li>
            <li className="list-item">
              <a
                href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored"
                target="_blank"
                rel="noreferrer noopener"
              >
                Deleting cookies in Firefox
              </a>
            </li>
            <li className="list-item">
              <a
                href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy"
                target="_blank"
                rel="noreferrer noopener"
              >
                Deleting cookies in Edge
              </a>
            </li>
          </ul>
        </div>
      </section>

      <section className="section pb-4">
        <div className="container has-max-ch mx-auto">
          <h2 className={`title ${respH(3)}`}>5. Servizi di Terze Parti</h2>
          <p>Potremmo utilizzare servizi di terze parti, tra cui:</p>
          <ul>
            <li>
              <strong>Cloudflare</strong> (hosting, sicurezza, ottimizzazione
              delle prestazioni).
            </li>
            <li>
              <strong>Mailchimp</strong> (per iscrizioni alla newsletter, se
              applicabile).
            </li>
            <li>
              <strong>Google Analytics</strong> (se attivato, per monitorare
              l’uso del sito).
            </li>
          </ul>
        </div>
      </section>

      <section className="section pb-4">
        <div className="container has-max-ch mx-auto content">
          <h2 className={`title ${respH(3)}`}>Newsletter</h2>
          <p>
            Questo sito offre la possibilità di iscriversi a una newsletter, per
            ricevere informazioni e aggiornamenti.
          </p>
          <p>
            Per gestire la mailing list e la registrazione da parte degli utenti
            utilizziamo il servizio offerto da{" "}
            <a
              href="https://mailchimp.com/it/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Mailchimp
            </a>
            .
          </p>
          <p>
            Mailchimp ottempera alla GDPR. Per approfondimenti{" "}
            <a
              href="https://mailchimp.com/it/gdpr/"
              rel="noopener noreferrer"
              target="_blank"
            >
              leggi qui
            </a>
            .
          </p>
          <p>
            Quando l'utente clicca sul link "Iscriviti alla newsletter",
            presente in ogni pagina di questo sito, si apre un{" "}
            <span class="is-italic">form</span>, ospitato sui server di
            Mailchimp. In questo form chiediamo esplicitamente all'utente di
            dare il suo consenso all'invio dei dati strettamente necessari a
            ricevere periodicamente, sul proprio indirizzo di posta elettronica,
            le nostre mail con novità e aggiornamenti.
          </p>
          <p>
            Si noti che i dati inviati dagli utenti vengono condivisi con
            Mailchimp e che Mailchimp può salvare tali dati su{" "}
            <span class="is-italic">servers</span> che risiedono negli Stati
            Uniti d'America.
          </p>
          <p>
            Nota che il GDPR, che regola e tutela i dati degli utenti che
            risiedono nella EU, non richiede che i dati personali degli
            individui dell'UE siano conservati solo negli stati membri dell'UE.
          </p>
          <p>
            Tuttavia il GDPR richiede che determinate condizioni siano
            soddisfatte prima che i dati personali vengano trasferiti al di
            fuori dell'UE. In particolare che i dati siano protetti nel
            trasferimento e che il paese o territorio laddove i dati personali
            sono trasferiti fornisca un livello adeguato di protezione degli
            stessi.
          </p>
          <p>
            Mailchimp dichiara la sua conformità all'esportazione europea dei
            dati:
            <a
              href=" https://mailchimp.com/help/mailchimp-european-data-transfers"
              rel="noopener noreferrer"
              target="_blank"
            >
              {" "}
              leggi qui
            </a>
          </p>
          <p>
            È importante notare che è sempre possibile rimuovere il proprio
            contatto dalla newsletter e non ricevere più aggiornamenti.
          </p>
          <p>
            È sufficiente cliccare sul <span class="is-italic">link</span>{" "}
            <span class="has-text-weight-bold is-italic">Unsubscribe</span> che
            compare in calce alle mail che inviamo.
          </p>
        </div>
      </section>

      <section className="section pb-4">
        <div className="container has-max-ch mx-auto content">
          <h2 className={`title ${respH(3)}`}>6. Altri utilizzi di dati</h2>
          <p>
            Quando l'utente invia una e-mail tramite il modulo di contatto
            presente nella pagina 'Contatti' o all'indirizzo riportato nel sito
            'info@fucinatalenti.it', vengono memorizzati i dati richiesti, per
            rispondere o per contattare in seguito l'utente. La persona non può
            inviare una richiesta di contatti, senza aver prima dato il consenso
            al trattamento dei dati. La base per questo trattamento dei dati è
            l'articolo 6 (1) (a) del GDPR.
          </p>
        </div>
      </section>

      <section className="section pb-4">
        <div className="container has-max-ch mx-auto content">
          <h2 className={`title ${respH(3)}`}>
            7. Conservazione e Sicurezza dei Dati
          </h2>
          <p>
            Ci affidiamo all'infrastruttura sicura di{" "}
            <strong>Cloudflare</strong>, che include crittografia SSL,
            protezione DDoS e altre misure di sicurezza avanzate.
          </p>
        </div>
      </section>

      <section className="section pb-4">
        <div className="container has-max-ch mx-auto content">
          <h2 className={`title ${respH(3)}`}>I tuoi diritti</h2>
          <p>
            Se sei protetto dalla General Data Protection Regulation (GDPR) hai
            certi diritti relativamente ai tui dati personali. Questi diritti
            includono il diritto a...
          </p>
          <p>
            ...<strong>accedere</strong> a tutti i dati raccolti su di te,
            incluse le informazioni riguardanti il come condivido, custodisco e
            metto in sicurezza e utilizzo i tui dati.
          </p>
          <p>
            ...<strong>richiedere una correzione</strong> dei tuoi dati
            personali.
          </p>
          <p>
            ...<strong>richiedere la cancellazione</strong> dei tuoi dati
            personali, sotto certe limitazioni dovute alle leggi applicabili.
          </p>
          <p>
            ...<strong>contestare</strong> l'utilizzo dei tuoi dati personali
            presso le appropriate autorità competenti. (Per favore comunicatemi
            prima le vostre richieste, in modo da poter io stesso rispondere
            alle vostre preoccupazioni!)
          </p>
          <p>
            ...<strong>richiedere una limitazione</strong> a come i tuoi dati
            personali vengono utilizzati.
          </p>
          <p>
            ...<strong>trasferire</strong> i tuoi dati personali a un soggetto
            terzo.
          </p>
        </div>
      </section>

      <section className="section pb-4">
        <div className="container has-max-ch mx-auto content">
          <h2 className={`title ${respH(3)}`}>Domande?</h2>
          <p>
            Per qualunque questione relativamente alla tua privacy o a questa{" "}
            <span className="is-italic">policy</span> puoi scrivere a{" "}
            {`${mail}`}
          </p>
          <p>Ultimo aggiornamento {`${lastUpdatePrivacy}`}</p>
        </div>
      </section>
    </Layout>
  );
};

export default PrivacyCookies;

export const query = graphql`
  query PrivacyPageQuery {
    site {
      siteMetadata {
        domain
        siteUrl
        mail
        lastUpdatePrivacy
      }
    }
  }
`;
